import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Certifications | MALT ELECTRICAL ENGINEERING CO. LTD';

},[]);



  return (
    <>
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Certifications</h2>
          {/* <p className='clients-para'></p>
      <p className='clients-para'></p>
      <p className='clients-para'></p>  */}
       
       
        {/* <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/MTN.jpg' alt='client' className='client-image'></img>
           </article>

          </div>  */}
    </section>
    {/* <section className='section-cover certifications-cover' >
      <div className='all-certs'>
        <img src='images/9001.jpg' className='certification-image'></img>
 <img src='images/14001.jpg' className='certification-image'></img>
 <img src='images/45001.jpg' className='certification-image'></img>
    </div>
    </section> */}
    <section className="cert-cover">
    
        <div>
          <img src='images/c1.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/c2.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/c3.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/c4.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/c5.jpg' className='certification-image'></img>
        </div>



    </section>
    </>
  )
}

export default Clients