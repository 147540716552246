import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Telecommunication & Networking Services | RAK ENGINEERING LTD';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Telecommunication & Networking Services</h3>
                     <p>Our Telecommunications & Networking Services focus primarily on design, building and maintenance of critical Telecommunications Infrastructure. We provide Telecommunications Infrastructure Solutions to the region’s leading Telecommunications Operators. Here, our services include the following:</p>
                    
                      <ul>
                      <li>Base Station Construction</li>
                      <li>Site and Way Leave Aquisition</li>
                      <li>Fibre Optic Network Deployment</li>
                      <li>Telecoms Equipment Installation</li>
                      <li>Site Audit</li>
                     </ul>
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

           
           </div>

    </section>
  )
}

export default Services