import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Clients | RAK ENGINEERING LTD';

},[]);



  return (
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
          <p className='clients-para'><strong>RAK ENGINEERING LTD</strong> has in the recent past worked on a number of projects and has registered commendable success among clients that include:</p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/MTN.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/ZTE.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/ROKO.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/TERRAIN.png' alt='client' className='client-image'></img>
                        <p><strong>TERRAIN SERVICES LTD</strong></p>
            </article>

            <article className='client'>
                      <img src='./images/shmz.png' alt='client' className='client-image'></img>
                        
            </article>

            <article className='client'>
                      <img src='./images/KONOIKE.jpg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/SOGEA.jpg' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/NWSC.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/EATON.jpg' alt='client' className='client-image'></img>            
            </article>
           

            <article className='client'>
                      <img src='./images/KOLIN.jpg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/UBC.jpg' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/EGYPRO.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/DOT.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/KAT.png' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/AI.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/PEARL.jpg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/MOTA.png' alt='client' className='client-image'></img>            
            </article>
          
          
          
          </div> 
    </section>
  )
}

export default Clients