import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | MALT ELECTRICAL ENGINEERING CO. LTD';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Our Team</h2>
           {/* <p>
</p>
<p>
</p> */}
  <table className='team-table'>
    <tr>
      <th>No.</th>
      <th>Name</th>
      <th>Position</th>
      <th>Qualification/Expertise</th>
      <th>Years of Experience</th>
    </tr>

     <tr>
      <td>1.</td>
      <td>KATAMBA JONAH </td>
      <td>MANAGING DIRECTOR</td>
      <td>ELECTRICAL ENGINEERING </td>
      <td>  {new Date().getFullYear() - 2007}</td>
    </tr>

    <tr>
      <td>2.</td>
      <td>SEMPA STEVEN  </td>
      <td>SUPERVISOR/ENGINEER</td>
      <td>ELECTRICAL ENGINEERING </td>
      <td>{new Date().getFullYear() - 2008}</td>
    </tr>

    <tr>
      <td>3.</td>
      <td>KIDDE IVAN </td>
      <td>OPERATIONS MANAGER</td>
      <td>ELECTRICAL ENGINEERING </td>
      <td>{new Date().getFullYear() - 2014}</td>
    </tr>

     <tr>
      <td>4.</td>
      <td>NAMISI JOSEPH </td>
      <td>SURVEYOR</td>
      <td>B.SC IN SURVEYING </td>
      <td>{new Date().getFullYear() - 2013}</td>
    </tr>

     <tr>
      <td>5.</td>
      <td>ANGELLA MATHA</td>
      <td>SURVEYOR</td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2020}</td>
    </tr>

    <tr>
      <td>6.</td>
      <td>TWEBAZE KENETH</td>
      <td>SURVEYOR</td>
      <td>DIP. IN SURVEYING </td>
      <td>{new Date().getFullYear() - 2017}</td>
    </tr>

    <tr>
      <td>7.</td>
      <td>KYABASINGA FRED </td>
      <td>SUPERVISOR</td>
      <td>DIP. IN ELECTRICAL ENGINEERING </td>
      <td>{new Date().getFullYear() - 2015}</td>
    </tr>

    <tr>
      <td>8.</td>
      <td>Mugenyi BaKti  </td>
      <td>ENVIRONMENTALIST </td>
      <td>B.SC IN ENVIRONMENTAL SCIENCE
 </td>
      <td>{new Date().getFullYear() - 2018}</td>
    </tr>

    <tr>
      <td>9.</td>
      <td>Kagwa robert  </td>
      <td>Community officer </td>
      <td>B. Arts IN SOCIAL SCIENCEs
 </td>
      <td>{new Date().getFullYear() - 2020}</td>
    </tr>

    <tr>
      <td>10.</td>
      <td>ERANYU JUDE </td>
      <td>SURVEYOR </td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2017}</td>
    </tr>

    <tr>
      <td>11.</td>
      <td>SEBULIJO STUAT </td>
      <td>SURVEYOR </td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2018}</td>
    </tr>

    <tr>
      <td>12.</td>
      <td>SSEMPERA NELSON</td>
      <td>SURVEYOR </td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2019}</td>
    </tr>

     <tr>
      <td>13.</td>
      <td>KAGWA JONATHAN </td>
      <td>SURVEYOR </td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2020}</td>
    </tr>

    <tr>
      <td>14.</td>
      <td>AMANYA PATRICK</td>
      <td>SURVEYOR </td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2020}</td>
    </tr>

    <tr>
      <td>15.</td>
      <td>TAMALE ROBERT</td>
      <td>SURVEYOR </td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2019}</td>
    </tr>

    <tr>
      <td>16.</td>
      <td>KABUYE HENRY </td>
      <td>ENGINEER</td>
      <td>DIP IN ELECTRICAL ENGINEERING </td>
      <td>{new Date().getFullYear() - 2017}</td>
    </tr>

    <tr>
      <td>17.</td>
      <td>SSEMPERA NELSON</td>
      <td>ENGINEER</td>
      <td>DIP IN ELECTRICAL ENGINEERING </td>
      <td>{new Date().getFullYear() - 2019}</td>
    </tr>

     <tr>
      <td>18.</td>
      <td>LUKENGE RICHARD </td>
      <td>SURVEYOR</td>
      <td>B.SC IN SURVEYING </td>
      <td>{new Date().getFullYear() - 2018}</td>
    </tr>

      <tr>
      <td>19.</td>
      <td>LUBINGA BALIKUDEMBE </td>
      <td>ENGINEER</td>
      <td>DIP IN ELECTRICAL ENGINEERING</td>
      <td>{new Date().getFullYear() - 2018}</td>
    </tr>

     <tr>
      <td>20.</td>
      <td>TWEBAZE ISAAC </td>
      <td>SOCIOLOGIST </td>
      <td>B. Arts IN SOCIAL SCIENCEs</td>
      <td>{new Date().getFullYear() - 2017}</td>
    </tr>

    <tr>
      <td>21.</td>
      <td>KATO AMON </td>
      <td>SURVEYOR </td>
      <td>B.SC IN SURVEYING</td>
      <td>{new Date().getFullYear() - 2020}</td>
    </tr>

 
  
  
  </table>


         </div>
    </section>
  )
}

export default Team