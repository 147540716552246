import React from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaTasks,FaHandshake,FaCheck,FaQuoteLeft, FaCogs} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {
  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'>MALT ELECTRICAL ENGENEERING CO. LTD is a duly registered company that specializes in Power Line Surveying & Designing, Power Line Construction, Electrical Installation & Maintenance and Supply of all Electrical Engineering materials. We are also prominent in Civil Engineering works including Road Construction, Building Construction, Surveying, etc. 
</p>
<p className='home-p'>We operate under the regulation of Electrical Regulation Authority (ERA) of Uganda No. ERA/EIP/CLX/016/1246, from 110V-415V (low voltage) and 6.6kV-220kV (high voltage).</p>
<p className='home-p'>Our experience and products in Electrical Engineering mainly cover Low, Medium and High Voltage. Our products and services are comply with GB, IEC, BS, ASTM, etc. Besides, we have the capacity to design and install all kinds of wires cables, protection units and all electrical engineering equipment according to the requirements of the client.
</p>

<p className='home-p'>We pledge to offer our customers the highest-quality Electrical and Civil Engineering Services while focusing on personalized service by offering convenience and rapid service. </p>
 
        </div>

    </section>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient( rgba(8, 8, 37, 0.85), rgba(0, 15, 80, 0.675)), url("./images/11.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            {/* <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div> */}
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>Over the past {new Date().getFullYear() - 2010 } years, we have successfully executed tremendous projects including construction of power lines (11kv & 33kv for over 1079 km), surveying and designing of 3095 km (both LV & HV) which was commissioned by UEDCL and UMEME Uganda under Rural Electrification Agency (REA). We also did the supply and delivery of materials.
</p>
        </div>
    </section>
    
 <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2> */}
              <div className='goals-list goals-list1'>
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description goal-description1'>Since we opened up a branch in Burundi we have been selling our products to REGIDESO BURUNDI, SOGEA SATOM BURUNDI and many other companies. Our products are standard of Shneider, Siemens and ABB from Turkey, Germany and the UK. </p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

        <div className='content-container goals-container goals-container1'>
           <article className='goals-text goals-text1'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Mission Statement</h2>
             <div className='line1'> <div className='line2'></div></div> */}
              <div className='goals-list goals-list1'>
                     {/* <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div> */}

                     {/* <div className='goal'>
                        
                          <p className='goal-description'></p>
                     </div> */}

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>MALT ELECTRICAL ENGINEERING CO. LTD has expertise in installation of power lines, transformer repairs, substation maintenance, electrical installation and maintenance/upgrading of substation from 10KV-220KV, material supply as well as intelligence systems of any nature. </p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>More so, we have a strong vendor relationship with most service-oriented vendors who are capable of shipping major parts or materials rapidly (on an overnight timeline in most cases) hence minimizing the system downtime for our customers. </p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>Our customers rely on us for complete installation. Some repairs which we do can even be carried out directly at the site by our well-equipped field personnel in order to avoid the significant expenses of returning the equipment to the plant and save time.</p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>We work with our customers to develop a repair plan that goes beyond replacing worn out parts. The ultimate result is a replacement that could be better than the original one.</p>
                     </div>

                      <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span> */}
                          <p className='goal-description'>Our company works in conjunction with different partners in Turkey, Germany, the UK, Kenya and China.</p>
                     </div>

                    
{/* <Link to="about-us" className='home-btn'>
         More About Us
         </Link> */}
                </div>
                   

           </article>

           {/* <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article> */}




       </div>

    

  </section>

   <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'> <div className='line2'></div></div>
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTasks></FaTasks></span>
          </div>
          <h3 className='home-service-title'>Service</h3>
          <p>Expedient and convenient Electrical and Civil Engineering Services.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Collaboration</h3>
          <p>Growth and maintenance of a referral network of customers.</p>

        </div>
        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div> */}
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCogs></FaCogs></span>
          </div>
          <h3 className='home-service-title'>Expertise</h3>
          <p>
            Expertise in Electrical and Civil Engineering Works.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck>  </span>
          </div>
          <h3 className='home-service-title'>Efficiency</h3>
          <p>Rapid order and delivery of electrical materials/components of electric power for instance transformers.</p>
        </div>


        

        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Our Approach</h3>
          <p>Our dedication to Respect, Integrity, Diversity, and Reliability has been the path to success for our company, our employees, and our clients. Our strength lies in our ability to find solutions. </p>
        </div> */}

        
    
    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section>
   
    
  
    </>
  )
}

export default Home