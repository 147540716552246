import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Equipment | MALT ELECTRICAL ENGINEERING CO. LTD';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
          <h2 className="home-h2 team-h2">Electrical Engineering Equipment</h2>
          

  <table className='team-table'>
    <tr>
      <th>No.</th>
      <th>TOOLS & EQUIPMENT </th>
      <th>QUANTITY </th>
      <th>STATUS </th>
    </tr>

     <tr>
      <td>1.</td>
      <td>AC ENERGY METER </td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>2.</td>
      <td>AC VOLTMETER MI 0.500V</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>3.</td>
      <td>AC AMMETER MI 0.25A</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>4.</td>
      <td>DC ENERGY METER A/H TYPE 220V, 5A</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>5.</td>
      <td>WHEAT STONE BRIDGE COMPLETE WITH GALVANOMETER AND BATTERY</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

      <tr>
      <td>6.</td>
      <td>SCIENTIFIC CALCULATOR</td>
      <td>10 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>7.</td>
      <td>CRIMPING TOOL</td>
      <td>7 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>8.</td>
      <td>HAMMER EXETOR TYPE 0.40<span style={{textTransform:'lowercase'}}>kg</span> WITH HANDLE</td>
      <td>30 </td>
      <td>  Owned </td>
    </tr>
     <tr>
      <td>9.</td>
      <td>3 POINT DC STARTERS</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>10.</td>
      <td>4 POINT DC STARTERS</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>11.</td>
      <td>DIRECT ON LINE STARTER</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>12.</td>
      <td>INSULATION RESISTANCE TESTER</td>
      <td>2 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>13.</td>
      <td>EARTH TESTING MEGGER</td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>14.</td>
      <td>SCREW DRIVES TOOLKITS</td>
      <td>7 </td>
      <td>  Owned </td>
    </tr>
      <tr>
      <td>15.</td>
      <td>SAFETY GLOVES</td>
      <td>200 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>16.</td>
      <td>SAFETY HELMET</td>
      <td>350 </td>
      <td>  Owned </td>
    </tr>

      <tr>
      <td>17.</td>
      <td>SAFETY HELMET</td>
      <td>300 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>18.</td>
      <td>OVERRAL</td>
      <td>300 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>19.</td>
      <td>SAFETY BOOTS</td>
      <td>300 </td>
      <td>  Owned </td>
    </tr>

      <tr>
      <td>20.</td>
      <td>SAFETY SUIT</td>
      <td>4 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>20.</td>
      <td>WHEEL BARROWS</td>
      <td>25 </td>
      <td>  Owned </td>
    </tr>
     <tr>
      <td>21.</td>
      <td>SPADES</td>
      <td>50 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>23.</td>
      <td>PANGA</td>
      <td>50 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>24.</td>
      <td>SHOVEL</td>
      <td>150 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>25.</td>
      <td>BUILDING LINE</td>
      <td>5 </td>
      <td>  Owned </td>
    </tr>

      <tr>
      <td>26.</td>
      <td>SQUARE</td>
      <td>7 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>27.</td>
      <td>TAPE MEASURE</td>
      <td>25 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>28.</td>
      <td>PLUMB BOB</td>
      <td>10 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>29.</td>
      <td>BOW SAW</td>
      <td>7 </td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>30.</td>
      <td>HAND SAW</td>
      <td>7 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>31.</td>
      <td>STEEL FLOAT/WOODEN FLOAT</td>
      <td>8 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>32.</td>
      <td>HAMMERS</td>
      <td>15</td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>33.</td>
      <td>GLOVES CIVIL</td>
      <td>250</td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>34.</td>
      <td>JERRY CANS</td>
      <td>63</td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>35.</td>
      <td>CONCRETE MIXER</td>
      <td>2</td>
      <td>  Owned </td>
    </tr>

      <tr>
      <td>36.</td>
      <td>STEEL BENDER</td>
      <td>3</td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>37.</td>
      <td>TRUCK/FUSO MITSUBISHI CRANE</td>
      <td>1</td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>38.</td>
      <td>SCAFFOLDS</td>
      <td>4</td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>39.</td>
      <td>VIBRATOR(POKER)</td>
      <td>3</td>
      <td>  Owned </td>
    </tr>

     <tr>
      <td>40.</td>
      <td>JUMPER</td>
      <td>2</td>
      <td>  Owned </td>
    </tr>

  </table>

           <h2 className="home-h2 team-h2 team-h2a">Civil Engineering Equipment</h2>
          

  <table className='team-table'>
    <tr>
      <th>No.</th>
      <th>EQUIPMENT </th>
      <th>MAKE </th>
      <th>QUANTITY </th>
      <th>STATUS </th>
    </tr>

     <tr>
      <td>1.</td>
      <td>Dozer </td>
      <td>D6R (CAT) </td>
      <td>1 </td>
      <td>  Owned </td>
    </tr>

    <tr>
      <td>2.</td>
      <td>Dozer </td>
      <td>KOMASTU 155(D9)  </td>
      <td>1 </td>
      <td>  Hired </td>
    </tr>

     <tr>
      <td>3.</td>
      <td>Escavator </td>
      <td>CAT 320D  </td>
      <td>1 </td>
      <td> Owned  </td>
    </tr>

     <tr>
      <td>4.</td>
      <td>Escavator </td>
      <td>CAT 300D </td>
      <td>1 </td>
      <td> Owned  </td>
    </tr>

      <tr>
      <td>5.</td>
      <td>Grader  </td>
      <td>CAR 140H  </td>
      <td>1 </td>
      <td> Owned  </td>
    </tr>

     <tr>
      <td>6.</td>
      <td>Roller  </td>
      <td>CAT   </td>
      <td>2 </td>
      <td> Owned  </td>
    </tr>

    <tr>
      <td>7.</td>
      <td>Dump Truck  </td>
      <td>SINO TRUCK   </td>
      <td>5 </td>
      <td>Hired  </td>
    </tr>

    <tr>
      <td>8.</td>
      <td>Water Browser </td>
      <td>ISUZU    </td>
      <td>1 </td>
      <td>Hired  </td>
    </tr>

      <tr>
      <td>9.</td>
      <td>Wheel Loader  </td>
      <td>CAT 950H    </td>
      <td>1 </td>
      <td>Hired  </td>
    </tr>


 
  
  
  </table>


         </div>
    </section>
  )
}

export default Team