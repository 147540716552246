import Carousel from 'react-bootstrap/Carousel';

function TelecomCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/37.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default TelecomCarousel;