import React, {useEffect} from 'react'

const Gallery = () => {
  useEffect(()=>{
document.title = 'Pictorial | MALT ELECTRICAL ENGINEERING CO. LTD';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Pictorial</h2>
      <div className='content-container gallery'>
 <article className='gallery-content'>
          <img src='./images/u1.jpg' className='gallery-image' alt='image'></img>
           <p>Installation of 33KV two power transformer through one protection </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/d1.jpg' className='gallery-image' alt='image'></img>
           <p>Installation of automatic power changer, AC/DC </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/u3.jpg' className='gallery-image' alt='image'></img>
           <p>Assembly and installation of two power bank panel </p>
         </article>


         <article className='gallery-content'>
          <img src='./images/p1.jpg' className='gallery-image' alt='image'></img>
           <p>Relocation of power line 33kv and associated
415v at Mirama Hills </p>
         </article>

        

         <article className='gallery-content'>
          <img src='./images/38.jpg' className='gallery-image' alt='image'></img>
           <p>Upgrade of powerline and commissioned Jinja </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/p2.jpg' className='gallery-image' alt='image'></img>
           <p>Maintance of over head 33kv with the suppply
of material  </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/3.jpg' className='gallery-image' alt='image'></img>
           <p>Inspection of the 132 kV sub-station maintenance at Tororo </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/p3.jpg' className='gallery-image' alt='image'></img>
           <p>Power line construction and commissioning  </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/35.jpg' className='gallery-image' alt='image'></img>
           <p>Architectural Surveying and Designing Power Line  </p>
         </article>
          <article className='gallery-content'>
          <img src='./images/p4.jpg' className='gallery-image' alt='image'></img>
           <p>Installation of 33 kV poles in Burundi </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/t1.jpg' className='gallery-image' alt='image'></img>
           <p>Installation of line and transformer protection panels (SF6 RMU's) </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/t2.jpg' className='gallery-image' alt='image'></img>
           <p>Laying of 33 kV power cables </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/sub1.jpg' className='gallery-image' alt='image'></img>
           <p>Sub-station maintenance, installation and commissioning
 </p>
         </article>

         {/* <article className='gallery-content'>
          <img src='./images/f1.jpg' className='gallery-image' alt='image'></img>
           <p>Operation Manager & Site
Engineer during fuel refilling</p>
         </article> */}

         <article className='gallery-content'>
          <img src='./images/e1.jpg' className='gallery-image' alt='image'></img>
           <p>During top soil
compacting with a Dosser (D9
machine) </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/e3.jpg' className='gallery-image' alt='image'></img>
           <p>During bush clearance &
road bed preparations with a D9
machine </p>
         </article>

          <article className='gallery-content'>
          <img src='./images/r1.jpg' className='gallery-image' alt='image'></img>
           <p>During spreading at
Muterere Trading Centre in
Mayuge District</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/r1.jpg' className='gallery-image' alt='image'></img>
           <p>During spreading at
Muterere Trading Centre in
Mayuge District</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/road2.jpg' className='gallery-image' alt='image'></img>
           <p>During murram loading at
the borrow pit at Mayuge District</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a1.jpg' className='gallery-image' alt='image'></img>
           <p> Construction of Plant House, Cable trenches and Transfomer
Plinth</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a2.jpg' className='gallery-image' alt='image'></img>
           <p>Plant House Under Construction</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a3.jpg' className='gallery-image' alt='image'></img>
           <p>Basement Ground Floor Slab Formwork</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a4.jpg' className='gallery-image' alt='image'></img>
           <p>Access Road Base Layer Construction
</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a5.jpg' className='gallery-image' alt='image'></img>
           <p>Water Spraying at the Access Road </p>
         </article>
         <article className='gallery-content'>
          <img src='./images/a6.jpg' className='gallery-image' alt='image'></img>
           <p>Access Road Base Layer Compaction</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a7.jpg' className='gallery-image' alt='image'></img>
           <p>Access Road Priming </p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a8.jpg' className='gallery-image' alt='image'></img>
           <p>Completed Asphalt Access Road and Parking </p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a9.jpg' className='gallery-image' alt='image'></img>
           <p>Palisade Fencing</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a10.jpg' className='gallery-image' alt='image'></img>
           <p>Substation Main Road Side View</p>
         </article>

           <article className='gallery-content'>
          <img src='./images/a11.jpg' className='gallery-image' alt='image'></img>
           <p>Substation Plant House </p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a12.jpg' className='gallery-image' alt='image'></img>
           <p>Completed substation </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a13.jpg' className='gallery-image' alt='image'></img>
           <p>Transfomer Placement onto Plinth</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a14.jpg' className='gallery-image' alt='image'></img>
           <p>FM 200 Fire suppression system and Electrical
lighting system</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a15.jpg' className='gallery-image' alt='image'></img>
           <p>Control System for Fire Suppression</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a16.jpg' className='gallery-image' alt='image'></img>
           <p>Installation of Main Distribution Board in the
Control Room</p>
         </article>

            <article className='gallery-content'>
          <img src='./images/a17.jpg' className='gallery-image' alt='image'></img>
           <p>Plant House Basement Cable Laying Underway
</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a18.jpg' className='gallery-image' alt='image'></img>
           <p>Transfomer Installation on Going
</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a19.jpg' className='gallery-image' alt='image'></img>
           <p>Excavations for Foundations</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/a20.jpg' className='gallery-image' alt='image'></img>
           <p>Transformer Plinths and Cable Trenches Steel and
Formwork</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a21.jpg' className='gallery-image' alt='image'></img>
           <p>Cable Trenches Steel Works on going </p>
         </article>

           <article className='gallery-content'>
          <img src='./images/a22.jpg' className='gallery-image' alt='image'></img>
           <p>Transfomer in Place, Fire wall on Going</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/a23.jpg' className='gallery-image' alt='image'></img>
           <p>Palisade Fencing </p>
         </article>

           <article className='gallery-content'>
          <img src='./images/a24.jpg' className='gallery-image' alt='image'></img>
           <p>Completed Fire Wall </p>
         </article>


      </div>

    </section>
  )
}

export default Gallery