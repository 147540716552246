import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = 'Our Services | MALT ELECTRICAL ENGINEERING CO. LTD';

},[]);


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Services</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Power Line Surveying & Designing</h3>
                     
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Power Line Construction
</h3>
                     {/* <p>We offers construction of high voltage power structures (substations, power transmission lines) and repair works. During project implementation, the company staff guarantees high quality of work and professional approach to each client. Over the years, we have built a number of distribution and low-voltage transmission lines including projects that are overhead, underground and straight through town. Our power services include the following:</p>
                     <ul>
                      <li>High Voltage Transmission</li>
                      <li>Substation Construction</li>
                      <li>Power Distribution</li>
                       <li>Power Line Maintenance</li>
                     
                     </ul> */}
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Electrical Installation & Maintenance</h3>
                       {/* <p>Road construction requires the creation of an engineered continuous roadbed, overcoming geographic obstacles and having grades low enough to permit vehicle or foot travel whilst meeting standards set by official guidelines. At RAK ENGINEERING LTD, we practice the art of road construction of highest quality. Below are our road construction services:</p>
                       <ul>
                    <li>  Highways and service roads</li>
<li>Car parks and hard standings.</li>
<li>Street lighting</li>
<li>Sidewalks, Paving and Curbing</li>
<li>Drainage</li>
                     
                     </ul> */}

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply of Electrical Engineering Materials</h3>
                     {/* Construction of Bio-digester septic tanks */}
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Road Construction
</h3>
                {/* <p>RAK ENGINEERING LTD offers Preventive maintenance (PM) Services on equipment and assets in order to keep them running and prevent any costly unplanned downtime from unexpected equipment failure. Here, we also do planning and scheduling of maintenance.</p> */}
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>


            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Building Construction</h3>
                     {/* <p>Here, we provide comprehensive emergency response services that help companies to plan and implement effective emergency communication and responses. Hence, saving lives, protecting property and the environment.</p> */}
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Earth Works</h3>
                {/* <p>We have served as Engineering consultants and contractors on almost every type of building project including, domestic housing, schools, hotels, sports centers, offices, factories, hospitals and health centers.  </p>
                  <p>RAK ENGINEERING LTD has highly qualified and experienced specialists in building construction, building design, construction management as well as construction supervision and maintenance work.  </p> */}
                  </article>

                  <article className='service-slides'>
                    {/* <MaintCarousel></MaintCarousel> */}
                 <BuildingsCarousel></BuildingsCarousel>
                  </article>     
            </div>
            
           </div>

    </section>
  )
}

export default Services